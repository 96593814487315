<template>
  <XToolbar>
    <template #body>
      <el-form :inline="true" class="ptb10">
        <el-form-item>
          <mx-radio-group v-model="query.dateType">
            <mx-radio-button label="hour">按小时</mx-radio-button>
            <mx-radio-button label="day">按日</mx-radio-button>
            <mx-radio-button label="month">按月 </mx-radio-button>
          </mx-radio-group>
        </el-form-item>
        <el-form-item label="选择日期：">
          <XDateRangePicker v-model="query.dateRange" :mode="query.dateType" />
        </el-form-item>
      </el-form>
    </template>
    <template #right>
      <div class="flex gap-8">
        <slot name="export" :query="query"></slot>
        <slot name="link-btn" :query="query"></slot>
      </div>
    </template>
  </XToolbar>
  <slot name="chart" :query="query"></slot>
</template>

<script setup lang="ts">
import { reactive, watch } from "vue";
import useQuery from "@/store/query";

const queryStore = useQuery();

const props = defineProps({
  cacheKey: {
    type: String,
    require: true,
    default: null,
  },
  query: {
    type: Object,
    default: () => {},
  },
});
type Query = {
  dateType: "hour" | "day" | "month";
  dateRange: [string, string];
};

const defs = queryStore.get(props.cacheKey) as Query;

const query = reactive<Query>({ dateType: "hour", dateRange: [""] as any });
if (props.query && props.query.dateType) {
  query.dateType = props.query.dateType;
  query.dateRange = props.query.dateRange;
} else if (defs && defs.dateType) {
  query.dateType = defs.dateType;
  query.dateRange = defs.dateRange;
}

watch(
  () => [query.dateType, query.dateRange],
  () => {
    queryStore.set(props.cacheKey, query);
  },
);
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  .main {
    flex: 1;
  }
  .right {
    text-align: right;
  }
}
</style>
